@use "colors";
@use "mixins";
@use "variables";

form {

  .form-control::-webkit-input-placeholder { color: colors.$white!important; }  /* WebKit, Blink, Edge */
  .form-control:-moz-placeholder { color: colors.$white!important; }  /* Mozilla Firefox 4 to 18 */
  .form-control::-moz-placeholder { color: colors.$white!important; }  /* Mozilla Firefox 19+ */
  .form-control:-ms-input-placeholder { color: colors.$white!important; }  /* Internet Explorer 10-11 */
  .form-control::-ms-input-placeholder { color: colors.$white!important; }  /* Microsoft Edge */
  // .form-control::-ms-input-placeholder { color: $white!important; }  /* Microsoft Edge */

  input.form-control, .form-control {
    @include mixins.transition-all(.3s);
    background: transparent;
    border-radius:0px;
    border: 0px;
    color:colors.$white;
    border-bottom: 1px solid colors.$white;
    opacity:variables.$fadedFormOpacity;
    &:focus {
      outline:none!important;
      outline-width: 0!important;
      box-shadow: none !important;
      border: 0px;
      color:colors.$white;
      border-bottom: 1px solid colors.$white;
      background: transparent;
      opacity:variables.$fullFormOpacity;
    }
  }
  .icon {
    @include mixins.transition-all(.3s);
    opacity:variables.$fadedFormOpacity;
  }
  &:hover {
    .icon {
      opacity:variables.$fullFormOpacity;
    }
  }
}
