@mixin transition-all($duration) {
  transition: all $duration ease-out;
}



@mixin transition-delay($delay) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  transition-delay: $delay;
}

@mixin center-center {
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
}

@mixin center-center-scale($scale) {
  position: absolute;
  top:50%;
  left:50%;
  transform-origin: center center;
  transform:translate(-50%,-50%) scale($scale);
}

@mixin left-bottom($offset) {
  position: absolute;
  bottom:$offset;
  left:$offset;
}

@mixin center-bottom($offset) {
  position: absolute;
  bottom:$offset;
  left:50%;
  transform-origin: center center;
  transform:translate(-50%,0px);
}

@mixin gradient($rgbval, $direction, $starttrans, $endtrans, $startpos, $endpos) {
  background: rgb($rgbval,$rgbval,$rgbval);
  background: -moz-linear-gradient($direction, rgba($rgbval,$rgbval,$rgbval,$starttrans) $startpos, rgba($rgbval,$rgbval,$rgbval,$endtrans) $endpos);
  background: -webkit-linear-gradient($direction, rgba($rgbval,$rgbval,$rgbval,$starttrans) $startpos, rgba($rgbval,$rgbval,$rgbval,$endtrans) $endpos);
  background: linear-gradient($direction, rgba($rgbval,$rgbval,$rgbval,$starttrans) $startpos, rgba($rgbval,$rgbval,$rgbval,$endtrans) $endpos);
}


@mixin box-shadow($offsetX, $offsetY, $radius, $color) {
  box-shadow: $offsetX $offsetY $radius $color;
}

@mixin blur($radius) {
  filter: blur($radius);
  -webkit-filter: blur($radius);
}

@mixin arrowbox($bordercolor, $backgroundcolor) {

	position: relative;
	border: 1px solid $bordercolor;

  &:after, &:before {
  	bottom: 100%;
  	left: 50%;
  	border: solid transparent;
  	content: " ";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
  }

  &:after {
  	border-color: rgba(17, 17, 17, 0);
  	border-bottom-color: $backgroundcolor;
  	border-width: 10px;
  	margin-left: -10px;
  }
  &:before {
  	border-color: rgba(34, 31, 31, 0);
  	border-bottom-color: $bordercolor;
  	border-width: 11px;
  	margin-left: -11px;
  }

}
