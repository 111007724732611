$white: #f9f9f9;
$white-true: #ffffff;
$black: #111111;
$black-true: #000000;
$midnight: #151616;
$shark: #221f1f;
$thunder: #292829;
$trinity: #353535;
$gravel: #464547;
$abbey: #4A4A4D;
$nevada: #6D6E71;
$blade: #757575;
$stone: #AAAAAA;
$silver: #bbbbbb;
$iron: #DCDDDE;
$greysky: #e5e5e5;
$smoke: #f5f5f5;

$nflxred: #e50914;
$meadow: #469350;
