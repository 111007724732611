@font-face{font-family:'NetflixSans';
  src:url(../font/NetflixSans_W_Lt.eot);
  src:url(../font/NetflixSans_W_Lt.woff) format("woff"), url(../font/NetflixSans_W_Lt.woff2) format("woff2"),url(../font/NetflixSans_Lt.ttf) format("truetype");
  font-weight:200;
  font-style:normal;
}

@font-face{font-family:'NetflixSans';
  src:url(../font/NetflixSans_W_Md.eot);
  src:url(../font/NetflixSans_W_Md.woff) format("woff"), url(../font/NetflixSans_W_Md.woff2) format("woff2"),url(../font/NetflixSans_Md.ttf) format("truetype");
  font-weight:400;
  font-style:normal;
}

@font-face{font-family:'NetflixSans';
  src:url(../font/NetflixSans_W_Bd.eot);
  src:url(../font/NetflixSans_W_Bd.woff) format("woff"), url(../font/NetflixSans_W_Bd.woff2) format("woff2"),url(../font/NetflixSans_Bd.ttf) format("truetype");
  font-weight:600;
  font-style:normal;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../font/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(../font/MaterialIcons-Regular.woff2) format('woff2'),
       url(../font/MaterialIcons-Regular.woff) format('woff'),
       url(../font/MaterialIcons-Regular.ttf) format('truetype');
}



.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}



html, body, button, input {
	font-family: 'NetflixSans', 'Helvetica', Arial, sans-serif;
  font-weight:300;
}
