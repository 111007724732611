$break-sm: 576px;
$break-md: 768px;
$break-lg: 992px;
$break-xl: 1200px;
$break-xxl: 1400px;
$pageMaxWidth: 1500px;

$font-light: 200;
$font-med: 400;
$font-bold: 600;

$headerHeight: 75px;
$headerContentPadding: 75px;
$heroHeight:400px;

$fadedFormOpacity: .75;
$fullFormOpacity: .75;
