@use "colors";
@use "mixins";
@use "variables";

.modal-content {
  background-color: #151313;
  border:1px solid rgba(153,153,153,.4);
  border-radius: 0px;
  min-height:350px;

  .modal-header {
    border:0px;

    .modal-title {
      font-size: 1rem;
      display: flex;
      align-items: center;
      flex: 1 1 auto !important;
    }
  }
  .modal-body {
    flex: 1 1 auto !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding:0rem 3rem;

    p {
      font-size: .9rem;
      line-height: 1.5rem;
    }

    .pin-input {
      margin:0rem auto 0rem auto;

      input, input:focus {
        outline:none;
        box-shadow: none;
        background-color: #333;
        border: 1px solid #777!important;
        border-radius: 0;
        color:#fff;
        font-weight: 300;
        font-size: 1.25rem;
        &::placeholder {
          color: #aaa;
          opacity: 1;
        }

        &::-ms-input-placeholder {
          color: #aaa;
        }

      }
    }

    .h4 {
      font-size: 24px;
      //text-transform: uppercase;
      //letter-spacing: .3em;
      font-weight:300;
      line-height: 1.3em;
    }
    .p {
      color:#aaa;
      margin:0 auto;
      max-width: 250px;
      font-size: 14px;
      font-weight:300;
      line-height: 1.4em;
    }

  }
  .modal-footer {
    border:0px;
    justify-content: center;
    padding-top:2rem;
    padding-bottom:3rem;
  }
  .h4 {
    font-weight: 300;
  }

  button, button:focus {
    outline:none!important;
    color:colors.$nflxred;
    background-color: transparent;
    border-radius: 0px;
    border: 1px solid colors.$nflxred!important;
    font-weight: variables.$font-med;
    text-transform: uppercase;
    padding: 0px 16px;
    line-height: 2.75em;
    position: relative;
    @include mixins.box-shadow(0px,0px,0px,rgba(0,0,0,0));
    overflow: hidden;

    &:hover {
      color:#fff;
      background-color: colors.$nflxred;
    }

  }

  .btn-close, .btn-close:hover, .btn-close:focus {
    border:0px!important;
    background-color: transparent!important;
    color:#fff!important;
    filter: invert(100%) sepia(0%) saturate(7489%) hue-rotate(147deg) brightness(93%) contrast(115%)!important;
    opacity: 1!important;
  }

}

.accordion {

  background-color: transparent!important;

  .accordion-item {
    border:0px!important;
    border-radius: 0px;
    background-color: transparent!important;

    .accordion-header {

      .accordion-button {
        color:#fff!important;
        font-weight:variables.$font-bold;
        background-color: transparent!important;
        border-width: 0px 0px 1px 0px!important;
        border-radius: 0px 0px 0px 0px!important;
        border-color:#666666!important;
        box-shadow:none!important;
        padding:0px;
        line-height: 4rem;
        text-transform: capitalize;
      }
    }
  }


  .accordion-body {
    color: #fff!important;
    padding:20px 0 0 0;
  }
}

.modal-backdrop.show {
  opacity: .8!important;
}

.video-container {
  position: relative;
  width: fit-content;
  margin: 0px auto;

  .video-sizer {
    max-width:100%;
    max-height: 80vh;
    width:auto;
    height:auto;
    opacity: 0;
    pointer-events: none;
  }

  .video-outer-wrapper {

    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;

    .video-inner-wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;

      iframe,video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
    }
  }
}

.watermark-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2147483647!important;
  pointer-events: none;
  .watermark-image {

  }
}